a{
	text-decoration: none;
	color: unset;
}
.dfr{
	display: flex;
	flex-direction: row;
}
.dfc{
	display: flex;
	flex-direction: column;
}
.dgc{
	display: grid;
	place-content: center;
}
/* gradient border*/
.borderPage {
	width: 100vw;
	min-height: 100vh;	
	background: linear-gradient(
		135deg,
		rgb(88, 56, 209), 
		rgb(56, 166, 221) 12% ,
		rgb(50 ,50 ,50) 17.5%, 
		rgb(50 ,50 ,50) 82.5%, 
		rgb(56, 166, 221) 90%,
		rgb(88, 56, 209)
	);
	position: absolute;
}
.container{
	background-color: rgb(50, 50, 50);
	width: 97.5vw;
	min-height: 97.5vh;
	margin: auto;
	border-radius: .5em;
    justify-content: space-between;
}
/* Navigation Bar */
.navBar{
	width: 100%;
	height: 10vh;
}
.title{
	flex: 5;
	position: relative;
	padding-left: 6em;
	padding-top: 1em;
	font-family: "Poppins", sans-serif;
}
.title h2 {
	font-size: 2em;
	position: absolute;
	transform: translate(-50%, -50%);
}
  
.title h2:nth-child(1) {
	color: transparent;
	-webkit-text-stroke: 2px rgb(56, 221, 174);
}
  
.title h2:nth-child(2) {
	color: rgb(56, 221, 174);
	animation: animatetitle 4s ease-in-out infinite;
}
.connexion{
	flex: 4;
	justify-content: space-around;
	margin: auto;
}
.connexion a {
	color: aliceblue ;
	font-size: 1em;
}
@keyframes animatetitle {
	0%,
	100% {
	  clip-path: polygon(
		0% 45%,
		16% 44%,
		33% 50%,
		54% 60%,
		70% 61%,
		84% 59%,
		100% 52%,
		100% 100%,
		0% 100%
	  );
	}
  
	50% {
	  clip-path: polygon(
		0% 60%,
		15% 65%,
		34% 66%,
		51% 62%,
		67% 50%,
		84% 45%,
		100% 46%,
		100% 100%,
		0% 100%
	  );
	}
  }
/* Content */
.form2{
	display: none;
}
    /* Left content */
        /* Home */
.helloWord{
	font-family: "Poppins", sans-serif;
	font-size: 5rem;
	height: 50vh;
	display: flex;
	align-items: center;
	justify-content: center;
}
.animationText{
	height: 150px;
	overflow: hidden;
	margin-left: 1rem;
}
.animationText > p > p {
	padding: 1rem;
	height: 2.81rem;
	margin-bottom: 2.81rem;

}
.animationText p:first-child{
	animation: textAnimation 10s infinite;
}
.txtAnim{
	border-radius: .5em;
	padding: .1em;
	text-align: center;
	cursor: default;
    color: aliceblue;
}
.first{
	background-color: rgb(88, 56, 209);
}
.second{
	background-color: rgb(56, 166, 221);
}
.third{
	background-color: rgb(56, 221, 174);
}
@keyframes textAnimation {
	0%{
		margin-top: 0;
	}
	10%{
		margin-top: -2;
	}
	20%{
		margin-top: -10rem;
	}
	30%{
		margin-top: -9.5rem;
	}
	40%{
		margin-top: -23rem;
	}
	50%{
		margin-top: -22rem;
	}
	60%{
		margin-top: -10rem;
	}
	70%{
		margin-top: -9.5rem;
	}
	80%{
		margin-top: 0;
	}
	90%{
		margin-top: -2;
	}
	100%{
		margin-top: 0;
	}
}
.descriptionBorder{
	background: linear-gradient(
		1deg,
		rgb(88, 56, 209), 
		rgb(56, 166, 221),
		rgb(56, 221, 174)
	);
	max-height: 100%;
	border-radius: .2em;
	width: 50vw;
	margin-left: 2em;
}
.descriContainer{
    background-color: rgb(50, 50, 50);
	margin-left: .5em;
	margin-top: 1em;
	max-height: 75vh;
	padding-left: 1em;
	padding-right: 1em;
	overflow-y: auto;
	overflow-x: hidden;
	overflow-wrap: break-word;
}
.description{
    color: aliceblue;
    font-family: Georgia, 'Times New Roman', Times, serif;
}
footer{
    text-align:center;
	margin: 1em;
	color: aliceblue;
}
        /* Skills */
.skillContainer{
	gap: .5em;
}
.skillTitle{
	font-style: italic;
	background-color: aliceblue;
	color: rgb(50 ,50 ,50);
	border-bottom-right-radius: 5em;
	width: fit-content;
	padding-right: 1.5em;
	cursor: pointer;
	border: none;
	outline: none;
}
.skillTitle:hover, .skillTitle:focus, .skillTitle::after{
	zoom: 125%;
}
.skill:hover, .skill:focus, .skill:active{
	background-color: rgb(56, 221, 174);
}
.exp:hover, .exp:focus, .exp:active{
	background-color: rgb(56, 166, 221);
}
.cer:hover, .cer:focus, .cer:active{
	background-color: rgb(111, 84, 209);
}
/* typewriter annimation */
.typeWriter{
	display: flex;
	margin: auto;
	width: max-content;
}
.typeWriter h1 {
	width: auto;
	overflow: hidden;
	white-space: nowrap;
	margin: 0 auto;
	letter-spacing: .15em;
	animation: typing 2s steps(30,end);
	background-image: linear-gradient(
    -225deg,
    rgb(88, 56, 209) 0%,    
    rgb(56, 166, 221) 37%,
    rgb(56, 221, 174) 50%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
@keyframes typing {
	from { width: 0}
	to { width: 100%}
  }
.skillContent{
	margin: 1em;
	justify-content: space-around;
	max-width: 30vw;
	text-align: center;
}
.logoskill{
	width: 5vw;
	margin: auto;
}
.expImg{
	width: 30vw;
	justify-self: center;
	margin: auto;
}
.subtitle{
	color: aliceblue;
	font-family: cursive;
}
.skillContent h5{
	color: aliceblue;
	font-size: x-large;
}
.certificat{
	width: 40vw;
}
		/*Project  */
.projectContainer{
	text-align: center;
	padding-bottom: 1em;
	color: aliceblue;
}
.overviewBtn{
	background-color: rgb(56, 166, 221) !important;
}
.slide{
	height: 75vh;
}
    /* Right Content */
.rContent{
	flex: 3;
}
.logoPixi{
	margin-right: 5em;
	margin: 0;
	height: 15vh;
	width: 10vw;
}
.about{
	font-family: "Poppins", sans-serif;
	cursor: default;
  background-image: linear-gradient(
    -225deg,
    rgb(88, 56, 209) 0%,    
    rgb(56, 166, 221) 10%,
    rgb(56, 221, 174) 20%,
	rgb(56, 166, 221) 30%,
	rgb(88, 56, 209) 40%,    
    rgb(56, 166, 221) 50%,
    rgb(56, 221, 174) 60%,
	rgb(56, 166, 221) 70%,
	rgb(88, 56, 209) 80%,
    rgb(56, 166, 221) 90%,
    rgb(56, 221, 174) 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
.about:hover{
	animation: textclip 5s linear infinite;
}
.aboutContenair{
	gap:3.5em;
	margin: 4rem;
	width: 50%;
}
.aboutTxt{
	padding: 1em;
	border-radius: .2em;
	text-align: center;
	color: aliceblue;
	cursor: pointer;
	border: 0px;
	font-size: 1em;
	background: linear-gradient(
		315deg,
		rgb(88, 56, 209), 
		rgb(56, 166, 221),
		transparent 10%,
		transparent 90%, 
		rgb(56, 166, 221),
		rgb(88, 56, 209)
	);
	transition: font-size 1s ease-out !important;
}
.aboutTxt:hover, .aboutTxt:focus, .about:active{
	background: linear-gradient(
		90deg,
		rgb(88, 56, 209), 
		rgb(56, 166, 221)
	);
	font-size: 1.25em;
}
@keyframes textclip {
	to {
	  background-position: 200% center;
	}
}
/* Reasponsive */
@media only screen and (max-width: 1024px){
	.lContent{
		width: 62.5vw;
	}
	.typeWriter{
		font-size: small;
	}
	.logoPixi{
		padding-right: 10vw;
	}
	.about{
		font-size: xx-large;
	}
	.descriContainer{
		margin-left: .25em;
		padding: 0;
	}
	.skillContent{
		max-width: none;
	}
	.skillContent h5{
		font-size: smaller;
	}
	.logoskill{
		width: 7.5vw;
	}
	.carousel-root{
		height: 55vh;
	}
  }
  @media only screen and (max-width: 767px){
	.form1{
		display: none;
	}
	.form2{
		display: flex;
		flex-direction: column;
	}
	.title{
		flex: 3;
		padding-left: 4em;
	}
	.title h2{
		font-size: 1.5em;
	}
	.fa-2xl{
		font-size: 1.5em;
	}
	.helloWord {
		height: 20vh;
		font-size: 2rem;
	}
	.animationText{
		height: 60px;
	}
	@keyframes textAnimation {
		0%{
			margin-top: 0;
		}
		10%{
			margin-top: -2;
		}
		20%{
			margin-top: -5rem;
		}
		30%{
			margin-top: -4.75rem;
		}
		40%{
			margin-top: -10rem;
		}
		50%{
			margin-top: -9.5rem;
		}
		60%{
			margin-top: -5rem;
		}
		70%{
			margin-top: -4.75rem;
		}
		80%{
			margin-top: 0;
		}
		90%{
			margin-top: -2;
		}
		100%{
			margin-top: 0;
		}
	}
	.logoPixi{
		padding: 0;
		width: 25vw;
	}
	.aboutContenair{
		margin: auto;
	}
	.descriptionBorder{
		width: unset;
		margin: auto;
	}
	.typeWriter {
		font-size: 0.5em;
	}
	.skillTitle{
		font-size: .5em;
	}
	.subtitle{
		font-size: 1em;
	}
	.certificat {
		width: 90vw;
	}
	.description{
		font-size: medium;
	}
  }